const socketEvents = {
  payers: {
    update: 'update-payerss',
    updated: 'payers-updated',
    create: 'create-payers',
    created: 'payers-created',
    delete: 'delete-payers',
    deleted: 'payers-deleted',
    refresh: 'payers-refresh',
  },
  categories: {
    update: 'update-categories',
    updated: 'categories-updated',
    create: 'create-categories',
    created: 'categories-created',
    delete: 'delete-categories',
    deleted: 'categories-deleted',
    refresh: 'categories-refresh',
  },
  suppliers: {
    update: 'update-suppliers',
    updated: 'suppliers-updated',
    create: 'create-suppliers',
    created: 'suppliers-created',
    delete: 'delete-suppliers',
    deleted: 'suppliers-deleted',
    refresh: 'suppliers-refresh',
  },
  types: {
    update: 'update-types',
    updated: 'types-updated',
    create: 'create-types',
    created: 'types-created',
    delete: 'delete-types',
    deleted: 'types-deleted',
    refresh: 'types-refresh',
  },
  users: {
    update: 'update-users',
    updated: 'users-updated',
    create: 'create-users',
    created: 'users-created',
    delete: 'delete-users',
    deleted: 'users-deleted',
    refresh: 'users-refresh',
  },
  clinics: {
    update: 'update-clinics',
    updated: 'clinics-updated',
    create: 'create-clinics',
    created: 'clinics-created',
    delete: 'delete-clinics',
    deleted: 'clinics-deleted',
    refresh: 'clinics-refresh',
  },
  rooms: {
    update: 'update-rooms',
    updated: 'rooms-updated',
    create: 'create-rooms',
    created: 'rooms-created',
    delete: 'delete-rooms',
    deleted: 'rooms-deleted',
    refresh: 'rooms-refresh',
  },
  services: {
    update: 'update-services',
    updated: 'services-updated',
    create: 'create-services',
    created: 'services-created',
    delete: 'delete-services',
    deleted: 'services-deleted',
    refresh: 'services-refresh',
  },
  patient: {
    update: 'update-patient',
    updated: 'patient-updated',
    create: 'create-patient',
    created: 'patient-created',
    delete: 'delete-patient',
    deleted: 'patient-deleted',
    refresh: 'patient-refresh',
  },
  notification: {
    created: 'notification-created',
    deleted: 'notification-deleted',
  },
  deviceCollection: {
    collected: 'device-data-collected'
  }
};

export default socketEvents;