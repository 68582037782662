import types from "../../statics/types";
const initState = {
  type: "",
  data: {}
};

export default function dataLoaderReducer(prevState = initState, action) {
  const { type, data } = action;

  switch (type) {
    case types.reduxTypes.SET_DATA_LOADER:
      // console.log('set dataLoader with data: ', data);
      return data;
    case types.reduxTypes.RESET_DATA_LOADER:
      return { ...initState };
    default:
      return prevState;
  }
}